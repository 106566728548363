import { graphql, useStaticQuery } from 'gatsby'
import {
  Query,
  Strapi_NavigationItemEntity,
  Strapi_WissensgruppeItemEntity,
  Strapi_WissensbereichItemEntity
} from 'gatsbyGraphqlTypes'
import { BreadCrumb } from '@hrk/types'
import { useTranslations } from './useTranslations'
import { useQueryParam } from './useQueryParam'
import { localizedSlug } from '../utils/localizedSlug'
import {
  ContentPageContext,
  HochschulportraitPageContext,
  WeiterbildungsandgebotPageContext
} from '../@types/PageContext.type'
import { useLocale } from './useLocale'

export interface UseNavigationItemsResult {
  navigationItems: Array<Strapi_NavigationItemEntity>
  collectBreadCrumbs: (
    context:
      Required<Pick<ContentPageContext | HochschulportraitPageContext | WeiterbildungsandgebotPageContext, 'slug' | 'title'>> &
      { siteConfig?: ContentPageContext['siteConfig'] } &
      { type: 'EditorialPages' | 'Universities' | 'TrainingOffers' | 'KnowledgeAreas' }
  ) => Array<BreadCrumb>
}

export const useNavigationItems = (): UseNavigationItemsResult => {
  const { strapi } = useStaticQuery<Query>(
    graphql`
      query {
        strapi {
          navigationItems(locale: "all", pagination: { limit: 10000 }) {
            data {
              id
              attributes {
                name
                parentId
                externalLink
                targetPageContent {
                  data {
                    attributes {
                      slug
                    }
                  }
                }
                locale
              }
            }
          }
          wissensgruppeItems(locale: "all", pagination: { limit: 10000 }) {
            data {
              id
              attributes {
                name
                shortText
                targetPageContent {
                  data {
                    attributes {
                      slug
                    }
                  }
                }
                locale
              }
            }
          }
          wissensbereichItems(locale: "all", pagination: { limit: 10000 }) {
            data {
              id
              attributes {
                name
                shortText
                targetPageContent {
                  data {
                    attributes {
                      slug
                    }
                  }
                }
                locale
              }
            }
          }
        }
      }
    `,
  )
  const navigationItems = strapi.navigationItems?.data ?? []
  const wissensgruppeItems = strapi.wissensgruppeItems?.data ?? []
  const wissensbereichItems = strapi.wissensbereichItems?.data ?? []
  const { pageLocale } = useLocale()
  const { search, layout } = useTranslations()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_t, _] = useQueryParam('t', '')

  const findItemBySlug = (items: (Strapi_NavigationItemEntity | Strapi_WissensgruppeItemEntity | Strapi_WissensbereichItemEntity)[], slug: string) => {
    return items.find(
      (item) =>
        (item.attributes?.targetPageContent?.data?.attributes?.slug as unknown as string) === slug ||
        item.attributes?.['externalLink'] === slug
    )
  }
  return {
    navigationItems,
    collectBreadCrumbs: ({ slug, title, type, siteConfig }) => {
      // we always need the home route
      const home: BreadCrumb = {
        slug: '/',
        name: findItemBySlug(navigationItems, "/")?.attributes?.name ?? "Home",
      }

      if (!slug || slug === "/") return [home]

      // after that we'll analyze the slug
      const splitted = slug.split('/')
        .filter(s => !!s) // '/404/' -(split)-> ['', '404', ''] -(filter)-> ['404']
      let processed = [
        ...splitted.map<BreadCrumb>((part, i) => {
          const pathPart = "/" + splitted.slice(0, i + 1).join("/")
          // The name of the breadcrumb Items comes from the NavItem
          const navItem = findItemBySlug(navigationItems, pathPart)
          let name = navItem?.attributes?.name
          name = name || findItemBySlug(wissensgruppeItems, pathPart)?.attributes?.name
          name = name || findItemBySlug(wissensbereichItems, pathPart)?.attributes?.name

          // XOR it must be constructed from the (slug-)part
          if (!name) {
            name = part.replace('/', '')
            name = name.charAt(0).toUpperCase() + name.slice(1, name.length)
          }

          return {
            slug: pathPart,
            name
          }
        })
      ]

      // after we've done the processing work, throw out all previous results... because anarchy!
      if (type === 'EditorialPages') {
        processed = [
          ...(_t === "EditorialPages" ? [{
            slug: localizedSlug(pageLocale, '/suche'),
            name: search.title,
            description: layout.backToSearchResults,
          }] : []),
          ...processed.slice(1),
        ]
      } else if (type === 'KnowledgeAreas') {
        processed = [_t === "EditorialPages" ? {
          slug: localizedSlug(pageLocale, '/suche'),
          name: search.title,
          description: layout.backToSearchResults,
        } : processed[0]]
      } else if (type === 'Universities') {
        processed = [processed[0]]
      } else if (type === 'TrainingOffers') {
        processed = [processed[0]]
      }

      const additive = processed[processed.length - 1]?.slug !== slug ?
        [{ slug: slug, name: title ?? siteConfig?.defaultTitle, description: title ?? siteConfig?.defaultDescription }] :
        []
      return [
        home,
        ...processed,
        ...additive
      ]
    },
  }
}
