import { AppLocale, MediaBundle } from '@hrk/types'
import { graphql, useStaticQuery } from 'gatsby'
import { Query } from 'gatsbyGraphqlTypes'

export type SiteMetadata = {
  metadata: { siteUrl: string; publisher: string }
  config: {
    defaultCover: MediaBundle
    defaultDescription: string
    defaultTitle: string
    bookmarkHeadline: string
    bookmarkIntroduction: string
    compareHeadline: string
    compareIntroduction: string
  }
  build: { buildTime: string }
}

export const useSiteMetadata = (locale: AppLocale): SiteMetadata => {
  const { site, siteBuildMetadata, strapi } = useStaticQuery<Query>(
    graphql`
      {
        site {
          siteMetadata {
            siteUrl
            publisher
          }
        }
        siteBuildMetadata {
          buildTime
        }
        strapi {
          de: siteConfig(locale: "de") {
            data {
              attributes {
                defaultCover
                defaultDescription
                defaultTitle
                bookmarkHeadline
                bookmarkIntroduction
                compareHeadline
                compareIntroduction
              }
            }
          }
          en: siteConfig(locale: "en") {
            data {
              attributes {
                defaultCover
                defaultDescription
                defaultTitle
                bookmarkHeadline
                bookmarkIntroduction
                compareHeadline
                compareIntroduction
              }
            }
          }
        }
      }
    `,
  )
  return {
    metadata: {
      siteUrl: site?.siteMetadata?.siteUrl ?? '',
      publisher: site?.siteMetadata?.publisher ?? '',
    },
    config: strapi[locale]?.data?.attributes ?? {
      defaultCover: { caption: '', description: '', fileName: '', src: '', thumbnailSrc: '' },
      bookmarkHeadline: '',
      bookmarkIntroduction: '',
      compareHeadline: '',
      compareIntroduction: '',
      defaultDescription: '',
      defaultTitle: '',
    },
    build: {
      buildTime: siteBuildMetadata?.buildTime ?? '',
    },
  }
}
